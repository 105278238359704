import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "session-timeout-warning"
}
const _hoisted_2 = { class: "warning-content" }

import { ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

const WARNING_TIME = 5 * 60 * 1000; // 5 minutes before timeout
const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes


export default /*@__PURE__*/_defineComponent({
  __name: 'SessionTimeoutWarning',
  setup(__props) {

const authStore = useAuthStore();
const router = useRouter();
const showWarning = ref(false);
const remainingMinutes = ref(5);

let warningTimeout: ReturnType<typeof setTimeout>;
let checkInterval: ReturnType<typeof setTimeout>;

const extendSession = () => {
  showWarning.value = false;
  setupTimeouts();
};

const logout = async () => {
  await authStore.logout();
  router.push("/login");
};

const setupTimeouts = () => {
  if (warningTimeout) clearTimeout(warningTimeout);
  if (checkInterval) clearInterval(checkInterval);

  warningTimeout = setTimeout(() => {
    showWarning.value = true;
    remainingMinutes.value = 5;
    checkInterval = setInterval(() => {
      remainingMinutes.value--;
      if (remainingMinutes.value <= 0) {
        logout();
      }
    }, 60000); // Check every minute
  }, SESSION_DURATION - WARNING_TIME);
};

onMounted(() => {
  if (!authStore.isRemembered) {
    setupTimeouts();
  }
});

onUnmounted(() => {
  if (warningTimeout) clearTimeout(warningTimeout);
  if (checkInterval) clearInterval(checkInterval);
});

return (_ctx: any,_cache: any) => {
  return (showWarning.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, " Tu sesión está a punto de expirar en " + _toDisplayString(remainingMinutes.value) + " minutos. ", 1),
          _createElementVNode("div", { class: "warning-actions" }, [
            _createElementVNode("button", {
              onClick: extendSession,
              class: "extend-button"
            }, " Mantener sesión activa "),
            _createElementVNode("button", {
              onClick: logout,
              class: "logout-button"
            }, "Cerrar sesión")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})