import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import { useAuthStore } from "@/stores/auth";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";

// Define a type that extends FirebaseUser with our custom fields
interface CustomUser extends Partial<FirebaseUser> {
  uid: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  role: string;
  emailVerified: boolean;
}

export const initializeAuth = () => {
  const auth = getAuth();
  const authStore = useAuthStore();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Create a properly typed user object
          const customUser: CustomUser = {
            ...user,
            ...userData,
            role: userData.role || "user",
            emailVerified: user.emailVerified,
          };
          // Ensure all required fields are present with correct types
          authStore.user = {
            ...customUser,
            email: customUser.email ?? null,
            emailVerified: customUser.emailVerified ?? true,
            displayName: customUser.displayName ?? null,
            photoURL: customUser.photoURL ?? null,
            role: customUser.role,
          };
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        authStore.user = null;
        authStore.userRole = null;
      }
    } else {
      authStore.user = null;
      authStore.userRole = null;
    }
    authStore.sessionChecked = true;
  });
};
