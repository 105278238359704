<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/store">Store</router-link> |
        <router-link to="/contact">Contact</router-link> |
        <router-link to="/login">Login</router-link>
      </nav>
      <p>&copy; 2024 American Cars Autopartes. All rights reserved.</p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
});
</script>

<style scoped>
.footer {
  padding: 1rem 0;
  text-align: center;
  background-color: #696969;
  color: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

nav {
  margin-bottom: 1rem;
}

a {
  color: white;
  text-decoration: none;
  margin: 0 0.5rem;
}

a:hover {
  text-decoration: underline;
}
</style>
