import { createStore } from "vuex";
import cart from "./modules/cart";

export default createStore({
  state: {
    user: null,
  },
  modules: {
    cart,
  },
});
