import { defineStore } from "pinia";
import { auth, db } from "@/firebase";
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

interface AuthUser {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  emailVerified: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // For additional userData properties
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null as AuthUser | null,
    isAuthenticated: false,
    sessionChecked: false,
    userRole: null,
    loading: true,
    isEmailVerified: false,
  }),

  actions: {
    async init() {
      try {
        // Set browser persistence
        await setPersistence(auth, browserLocalPersistence);

        return new Promise((resolve) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
              try {
                const userDoc = await getDoc(doc(db, "users", user.uid));
                const userData = userDoc.data();

                this.user = {
                  uid: user.uid,
                  email: user.email,
                  displayName: user.displayName,
                  photoURL: user.photoURL,
                  emailVerified: user.emailVerified,
                  ...userData,
                } as AuthUser;
                this.isAuthenticated = true;
                this.userRole = userData?.role || "client";
                this.isEmailVerified = user.emailVerified;
              } catch (error) {
                console.error("Error fetching user data:", error);
                this.user = null;
                this.isAuthenticated = false;
              }
            } else {
              this.user = null;
              this.isAuthenticated = false;
              this.userRole = null;
            }
            this.sessionChecked = true;
            this.loading = false;
            resolve(true);
          });
        });
      } catch (error) {
        console.error("Error initializing auth:", error);
        this.loading = false;
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async login(email: string, password: string, _rememberMe: boolean) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
        const userData = userDoc.exists() ? userDoc.data() : {};
        this.user = {
          ...userCredential.user,
          ...userData,
        };
        this.isAuthenticated = true;
        this.userRole = userData.role || "client";
        this.isEmailVerified = userCredential.user.emailVerified;

        return true;
      } catch (error) {
        console.error("Login error:", error);
        throw error;
      }
    },
  },
});
