<template>
  <div v-if="showWarning" class="session-timeout-warning">
    <div class="warning-content">
      <p>
        Tu sesión está a punto de expirar en {{ remainingMinutes }} minutos.
      </p>
      <div class="warning-actions">
        <button @click="extendSession" class="extend-button">
          Mantener sesión activa
        </button>
        <button @click="logout" class="logout-button">Cerrar sesión</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();
const showWarning = ref(false);
const remainingMinutes = ref(5);

const WARNING_TIME = 5 * 60 * 1000; // 5 minutes before timeout
const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes

let warningTimeout: ReturnType<typeof setTimeout>;
let checkInterval: ReturnType<typeof setTimeout>;

const extendSession = () => {
  showWarning.value = false;
  setupTimeouts();
};

const logout = async () => {
  await authStore.logout();
  router.push("/login");
};

const setupTimeouts = () => {
  if (warningTimeout) clearTimeout(warningTimeout);
  if (checkInterval) clearInterval(checkInterval);

  warningTimeout = setTimeout(() => {
    showWarning.value = true;
    remainingMinutes.value = 5;
    checkInterval = setInterval(() => {
      remainingMinutes.value--;
      if (remainingMinutes.value <= 0) {
        logout();
      }
    }, 60000); // Check every minute
  }, SESSION_DURATION - WARNING_TIME);
};

onMounted(() => {
  if (!authStore.isRemembered) {
    setupTimeouts();
  }
});

onUnmounted(() => {
  if (warningTimeout) clearTimeout(warningTimeout);
  if (checkInterval) clearInterval(checkInterval);
});
</script>

<style scoped>
.session-timeout-warning {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.warning-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.extend-button,
.logout-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.extend-button {
  background-color: #0c1cb6;
  color: white;
}

.logout-button {
  background-color: #f0f0f0;
  color: #333;
}
</style>
