<template>
  <a
    href="https://wa.me/573025220093"
    target="_blank"
    rel="noopener noreferrer"
    class="whatsapp-button"
    title="Contáctanos por WhatsApp"
  >
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      class="whatsapp-icon"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -960 -491 -2229
        272 -3065 423 -463 951 -740 1585 -830 118 -17 511 -16 635 0 579 80 1097 337
        1491 739 130 132 157 163 243 277 256 341 423 757 488 1211 17 118 17 512 0
        630 -43 303 -122 561 -247 814 -134 268 -270 459 -483 674 -395 400 -889 649
        -1457 733 -123 18 -478 26 -600 13z m635 -1012 c895 -213 1403 -1117 1115
        -1983 -74 -224 -192 -411 -370 -590 -225 -226 -468 -358 -785 -425 -87 -19
        -137 -23 -290 -23 -253 -1 -411 32 -634 130 -55 24 -107 44 -116 44 -8 0 -190
        -47 -405 -104 -214 -56 -391 -102 -392 -101 -2 1 46 179 105 395 l108 394 -47
        96 c-106 215 -153 420 -153 670 1 428 152 791 454 1090 237 235 537 381 885
        434 17 2 118 3 225 1 166 -3 211 -7 300 -28z"
        />
        <path
          d="M2505 3868 c-490 -35 -938 -385 -1095 -856 -50 -152 -64 -242 -64
        -412 0 -259 49 -432 195 -686 l23 -42 -62 -227 c-34 -125 -61 -230 -58 -232 2
        -2 108 24 236 57 l233 62 121 -61 c199 -99 372 -141 587 -141 537 0 1028 354
        1198 866 51 155 64 234 64 404 0 122 -5 177 -22 260 -109 511 -511 903 -1021
        994 -109 19 -210 23 -335 14z m-303 -581 c32 -36 161 -355 154 -382 -3 -12
        -33 -57 -66 -101 -33 -43 -60 -85 -60 -94 0 -19 80 -138 142 -211 67 -79 194
        -175 301 -230 135 -67 142 -67 219 28 96 117 105 125 131 118 30 -9 316 -143
        346 -162 22 -14 23 -20 18 -82 -3 -37 -13 -86 -22 -109 -21 -52 -97 -122 -173
        -159 -53 -26 -66 -28 -177 -28 -111 0 -127 3 -215 34 -145 52 -235 95 -344
        168 -210 140 -459 421 -561 634 -44 93 -58 163 -53 269 5 99 30 164 96 246 54
        66 90 83 175 84 59 0 71 -3 89 -23z"
        />
      </g>
    </svg>
  </a>
</template>

<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  background-color: white;
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
