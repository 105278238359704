<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <header class="navbar">
    <router-link to="/" class="logo-link">
      <img
        src="@/assets/logo.png"
        alt="American Cars Autopartes Logo"
        class="logo"
      />
    </router-link>
    <nav class="nav-links">
      <router-link to="/"><strong>Inicio</strong></router-link>
      <router-link to="/store"><strong>Tienda</strong></router-link>
      <router-link to="/contact"><strong>Contacto</strong></router-link>
      <router-link to="/login"><strong>Login</strong></router-link>
    </nav>

    <button class="cart-button" @click="$emit('openCart')">
      <font-awesome-icon :icon="['fas', 'cart-shopping']" />
      <span v-if="cartItemCount > 0" class="cart-count">{{
        cartItemCount
      }}</span>
    </button>
  </header>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const cartItemCount = computed(() => store.getters["cart/itemCount"]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cartTotal = computed(() => store.getters["cart/cartTotal"]);

// eslint-disable-next-line no-undef
defineEmits(["openCart"]);
</script>

<style scoped>
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  align-items: center;
  padding: 0.3rem;
  justify-content: space-between;
  background-color: #fff;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.4em;
}

.logo {
  height: 100px;
  width: auto;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
}

.logo-link {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 3rem;
  margin-left: auto;
  padding-right: 20rem;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  transition: color 0.2s ease;
  padding: 0.5rem 0;
}

.nav-links a:hover {
  color: var(--primary-blue);
}

.nav-links a.router-link-active {
  color: var(--primary-blue);
}

.cart-button {
  position: relative;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--primary-red);
  padding: 0.5rem 1rem;
}

.cart-count {
  position: relative;
  top: -8px;
  right: -8px;
  background: var(--primary-red);
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  min-width: 20px;
  text-align: center;
}
</style>
