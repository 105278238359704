/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuthStore } from "@/stores/auth";
import type { User } from "@/types/user";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/store",
      name: "Store",
      component: () => import("@/views/Store.vue"),
    },
    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/views/Contact.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/Register.vue"),
    },
    {
      path: "/email-verification",
      name: "EmailVerification",
      component: () => import("@/views/EmailVerification.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/client-dashboard",
      name: "ClientDashboard",
      component: () => import("@/views/CustomerDashboard.vue"),
      meta: {
        requiresAuth: true,
        requiresVerification: true,
        requiresClient: true,
      },
    },
    {
      path: "/staff-dashboard",
      name: "StaffDashboard",
      component: () => import("@/views/StaffDashboard.vue"),
      meta: {
        requiresAuth: true,
        requiresStaff: true,
      },
    },
    {
      path: "/client-orders/:id",
      name: "ClientOrders",
      component: () => import("@/views/ClientOrders.vue"),
      meta: { requiresAuth: true, requiresStaff: true },
    },
    {
      path: "/checkout",
      name: "Checkout",
      component: () => import("@/views/Checkout.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/payment-result/:orderId",
      name: "PaymentResult",
      component: () => import("@/views/PaymentResult.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      component: () => import("@/views/Unauthorized.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Wait for auth initialization
  if (!authStore.sessionChecked) {
    await authStore.init();
  }

  // Handle authenticated routes
  if (to.meta.requiresAuth) {
    if (!authStore.isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
      return;
    }

    // Check email verification requirement
    if (to.meta.requiresVerification && !authStore.isEmailVerified) {
      next("/email-verification");
      return;
    }

    // Check role-based access
    if (to.meta.requiresStaff && authStore.userRole !== "staff") {
      next("/unauthorized");
      return;
    }
  }

  // Redirect authenticated users from login/register
  if (
    (to.path === "/login" || to.path === "/register") &&
    authStore.isAuthenticated
  ) {
    next(
      authStore.userRole === "staff" ? "/staff-dashboard" : "/client-dashboard"
    );
    return;
  }

  // Default allow
  next();
});

export default router;
