import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/store" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Store")
          ])),
          _: 1
        }),
        _cache[5] || (_cache[5] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/contact" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Contact")
          ])),
          _: 1
        }),
        _cache[6] || (_cache[6] = _createTextVNode(" | ")),
        _createVNode(_component_router_link, { to: "/login" }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Login")
          ])),
          _: 1
        })
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("p", null, "© 2024 American Cars Autopartes. All rights reserved.", -1))
    ])
  ]))
}