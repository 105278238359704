import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "nav-links" }
const _hoisted_3 = {
  key: 0,
  class: "cart-count"
}

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  emits: ["openCart"],
  setup(__props) {

const store = useStore();
const cartItemCount = computed(() => store.getters["cart/itemCount"]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cartTotal = computed(() => store.getters["cart/cartTotal"]);

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "logo-link"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "American Cars Autopartes Logo",
          class: "logo"
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("strong", null, "Inicio", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/store" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("strong", null, "Tienda", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/contact" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("strong", null, "Contacto", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/login" }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("strong", null, "Login", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("button", {
      class: "cart-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openCart')))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'cart-shopping'] }),
      (cartItemCount.value > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(cartItemCount.value), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})