import { Module } from "vuex";

interface CartItem {
  productId: string;
  name: string;
  price: number;
  quantity: number;
  imageUrl: string;
  stock: number;
}

interface CartState {
  items: CartItem[];
}

const cartModule: Module<CartState, unknown> = {
  namespaced: true,
  state: () => ({
    items: [],
  }),

  getters: {
    cartTotal: (state) => {
      return state.items.reduce((total, item) => {
        return total + Number(item.price) * Number(item.quantity);
      }, 0);
    },

    itemCount: (state) => {
      return state.items.reduce(
        (count, item) => count + Number(item.quantity),
        0
      );
    },
  },

  mutations: {
    updateQuantity(
      state,
      { productId, quantity }: { productId: string; quantity: number }
    ) {
      const item = state.items.find((i) => i.productId === productId);
      if (item) {
        item.quantity = Number(quantity);
      }
    },

    addItem(state, item: CartItem) {
      const existingItem = state.items.find(
        (i) => i.productId === item.productId
      );
      if (existingItem) {
        const newQuantity =
          Number(existingItem.quantity) + Number(item.quantity);
        if (newQuantity <= item.stock) {
          existingItem.quantity = newQuantity;
        }
      } else {
        state.items.push({
          ...item,
          quantity: Number(item.quantity),
          price: Number(item.price),
        });
      }
    },

    removeItem(state, productId: string) {
      state.items = state.items.filter((item) => item.productId !== productId);
    },
  },

  actions: {
    updateQuantity({ commit }, { productId, quantity }) {
      commit("updateQuantity", {
        productId,
        quantity: Number(quantity),
      });
    },

    addToCart({ commit }, { item }) {
      commit("addItem", {
        ...item,
        quantity: Number(item.quantity),
        price: Number(item.price),
      });
    },

    removeFromCart({ commit }, productId) {
      commit("removeItem", productId);
    },
  },
};

export default cartModule;
